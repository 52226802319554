<template>
  <div class="mb-5 px-6">
    <div class="rounded-lg bg-slate-50 p-4">
      <h2 class="mb-3 text-sm/4 font-medium">
        {{ $t('company-factory.approved-factory.banner-title') }}
      </h2>
      <div class="grid grid-cols-2 gap-3">
        <div v-for="factory in factories" :key="factory.id" class="text-sm/4">
          <span>{{ factory.code }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  useApprovedFactoryStore,
  useCompanyFactoryStore,
  useUserStore,
} from '@/stores';
import { ListingPreview } from '@/types';
import { computed } from 'vue';

const props = defineProps<{
  listing: ListingPreview;
}>();

const userStore = useUserStore();
const approvedFactoryStore = useApprovedFactoryStore();
const companyFactoryStore = useCompanyFactoryStore();

const isYourListing = computed(() => {
  return userStore.isMyCompany(props.listing.owner.id);
});

const factories = computed(() => {
  if (props.listing.type === 'BID') {
    return isYourListing.value
      ? approvedFactoryStore.filter(props.listing.factories)
      : companyFactoryStore.filter(props.listing.factories);
  }

  if (props.listing.type === 'OFFER') {
    return isYourListing.value
      ? companyFactoryStore.filter(props.listing.factories)
      : approvedFactoryStore.filter(props.listing.factories);
  }

  return [];
});
</script>
